import { vuexfireMutations, firestoreAction } from"vuexfire";
import Vue from 'vue';
import Vuex from 'vuex';
import {db} from './db';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        projects: null,
    },
    mutations: {
        ...vuexfireMutations,
    },
    actions: {
        bindProjects: firestoreAction(({ bindFirestoreRef }) => {
            return bindFirestoreRef('projects', db.collection("projects").orderBy("order"));
        }),
    }
})