<template>
    <div class="mt-2 contact-form" id="contact">
        <h1>Get In Touch</h1>
        <p v-if="!canSubmit">
            Thanks for reaching out! I'll get back to you as soon as I can.
        </p>
        <p v-if="canSubmit">I'd love to hear from you. You can send me an email at <a href="mailto:jack@oenoke.com">jack@oenoke.com</a> or 
        fill out the form below.</p>
        <form v-if="canSubmit" class="mx-auto" style="text-align:left;">
            <div class="form-group mb-3">
                <label for="nameInput" class="form-label">Name</label>
                <input type="email" class="form-control" id="nameInput" aria-describedby="emailHelp" placeholder="Enter your name" v-model="form.name">
            </div>
            <div class="form-group mb-3">
                <label for="emailInput" class="form-label">Email Address</label>
                <input type="email" class="form-control" id="emailInput" aria-describedby="emailHelp" placeholder="Enter your email" v-model="form.email">
            </div>
            <div class="form-group mb-3">
                <label for="reasonInput" class="form-label">Reason</label>
                <select id="reasonInput" class="form-control" v-model="form.reason">
                    <option v-for="reason in reasons" :key="reason">{{reason}}</option>
                </select>
            </div>
            <div class="form-group mb-5">
                <label for="messageInput" class="form-label">Message</label>
                <textarea class="form-control" id="messageInput" rows="3" v-model="form.message" placeholder="Briefly describe your inquiry"></textarea>
            </div>
        </form>
        <div v-if="canSubmit" class="w-100 d-flex flex-row justify-content-center">
            <div v-if="submitting" class="spinner-border submit-spinner" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <button v-else class="contact-btn" @click="submitRequest">Submit</button>
        </div>
    </div>
</template>

<script>
import {db} from '../db';

export default {
    data() {
        return {
            form: {
                name: null,
                email: null,
                reason: null,
                message: null,
            },
            reasons: [
                "App/Website Idea",
                "Consulting",
                "Job Inquiry",
                "General Contact",
                "Other",
            ],
            submitting: false,
            justSubmitted: false,
        }
    },
    computed: {
        canSubmit() {
            if (this.justSubmitted) {
                return false;
            }

            let requestSubmitted = window.localStorage.getItem("requestSubmitted");

            if (requestSubmitted == null) {
                return true;
            }

            let dateSubmitted = new Date(Date.parse(requestSubmitted));

            let now = new Date();
            // now = new Date(now.getTime() + 10^10)

            if (now.getDay() == dateSubmitted.getDay() && now.getMonth() == dateSubmitted.getMonth() && now.getFullYear() == dateSubmitted.getFullYear()) {
                // It's the same day, so no more submissions
                return false;
            }

            return true;
        }
    },
    methods: {
        submitRequest() {
            if (Object.values(this.form).includes(null) || Object.values(this.form).includes("")) {
                alert("Please fill out all the fields.");
                return;
            }
            this.submitting = true;

            db.collection("requests").add({...this.form, "submittedOn": new Date()})
            .then(() => {
                window.localStorage.setItem("requestSubmitted", new Date());
                this.submitting = false;
                this.justSubmitted = true;
                alert("Request submitted successfully.");
            })
            .catch((err) => {
                console.log(err);
                this.submitting = false;
                alert("Error submitting request. Please try again.");
            });
        }
    },
    mounted() {
        // window.localStorage.clear();
    }
}
</script>

<style scoped>
    .form-label {
        font-size: 1.2em;
        margin-left: 5px;
    }

    .contact-form {
        margin-left: auto;
        margin-right: auto;
        width: 75%;
    }

    @media (max-width: 786px) {
        .contact-form {
            width: 90%;
        }
    }

    .contact-btn {
        border: 1px solid #175676;
        border-radius: 4px;
        font-size: 1.4em;
        min-width: 40%;
        padding-top: 8px;
        padding-bottom: 8px;
        background-color: #175676;
        color: white;
    }

    .contact-btn:hover {
        font-weight: bold;
    }

    .submit-spinner {
        color: #175676;
    }
</style>