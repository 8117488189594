// Import the functions you need from the SDKs you need

import firebase from "firebase";
import 'firebase/firestore';
import 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCVbyYFzPOp3QW3H97WwT0IpPuJuX_9wO8",

  authDomain: "cascon-c8a72.firebaseapp.com",

  databaseURL: "https://cascon-c8a72.firebaseio.com",

  projectId: "cascon-c8a72",

  storageBucket: "cascon-c8a72.appspot.com",

  messagingSenderId: "553473312709",

  appId: "1:553473312709:web:c4ada71bd6cd874e2b2dac"
  
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const fieldPath = firebase.firestore.FieldPath;
const fieldValue = firebase.firestore.FieldValue;
// const auth = firebaseApp.auth();
const storage = firebaseApp.storage();


const { TimeStamp } = firebase.firestore;

export {
  db,
  // auth,
  storage,
  fieldPath,
  fieldValue,
  TimeStamp, 
}