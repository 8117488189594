<template>
    <div class="d-flex flex-row px-1 py-1">
        <div class="logo-container">
            <a href="/">
                <img src="../assets/logo.png" alt="Jack Heinemann" class="logo-img">
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
    .logo-img {
        width: 100%;
        max-width: 40px;
    }

</style>