<template>
    <div class="home-header">
        <Navbar class="mb-2"/>
        <div class="d-flex flex-row justify-content-evenly align-items-center"> 
            <div class="title-container">
                <div class="title-content">
                    <h1>Jack Heinemann</h1>
                    <div class="d-flex flex-row justify-content-center">
                        <h2 style="visibility:hidden;">a</h2>
                        <h2 id="titleText"></h2>
                        <h2 style="visibility:hidden;">a</h2>
                    </div>
                    <hr class="mb-4">
                    <div class="d-flex flex-row justify-content-evenly">
                        <a href="https://github.com/jackheinemann">
                            <i class="fa-brands fa-github fa-xl"></i>
                        </a>
                        <a href="https://stackoverflow.com/users/7058142/holycamolie">
                            <i class="fa-brands fa-stack-overflow fa-xl"></i>
                        </a>
                        <a href="https://twitter.com/Jack75235486588">
                            <i class="fa-brands fa-twitter fa-xl"></i>
                        </a>
                        <a href="mailto:jack@oenoke.com">
                            <i class="fa-solid fa-envelope fa-xl"></i>
                        </a>
                    </div>
                    <div class="d-flex flex-row justify-content-center">
                        <h2 style="visibility:hidden;">a</h2>
                        <h2 style="visibility:hidden;">
                            {{longestTitle}}
                        </h2>
                        <h2 style="visibility:hidden;">a</h2>
                    </div>
                    <div class="d-flex flex-row btn-row">
                        <button class="portfolio-btn filled-btn" @click="navigateTo('portfolio')">
                            Portfolio
                        </button>
                        <button class="portfolio-btn outlined-btn" @click="navigateTo('contact')">
                            Your Idea
                        </button>
                    </div>
                </div>
            </div>
            <div class="profile-img-container" v-if="!isMobile">
                <img src="../assets/profile.jpg" alt="" class="rounded-circle profile-img">
            </div>
        </div>
        <div class="my-1" style="visibility:hidden;">_</div>
    </div>
</template>

<script>
import Navbar from './Navbar.vue';
// import {db} from '../db';
export default {
    components: {
        Navbar
    },
    data() {
        return {
            titles: ["Mobile Developer", "Web Developer", "Full-Stack Developer", "Freelancer", "Inventor", "Writer", "Runner"], // list of typewriter titles
            titleIndex: 0,
            letterIndex: 0, // for iterating through each title letter
            typeForward: true, // tracks whether the typewriter is typing (forward) or deleting
            windowWidth: null,
        }
    },
    computed: {
        currentTitle() {
            let title = document.getElementById("titleText")?.innerHTML ?? "";
            // if (title == null) return "";
            return title;
        },
        longestTitle() {
            let longest = "";
            this.titles.forEach((e) => {
                if (e.length > longest.length) {
                    longest = e;
                }
            });
            return longest;
        },
        isMobile() {
            return this.windowWidth <= 768;
        }
    },
    methods: {
        navigateTo(destination) {
            document.getElementById(destination).scrollIntoView();
        },
        typeWriter() {
            let currentTitle = this.titles[this.titleIndex];

            const interval = 50; //ms

            if (this.letterIndex < currentTitle.length && this.letterIndex >= 0) {
                // actively changing the text
                let titleText = document.getElementById("titleText");
                if (this.typeForward) {
                    titleText.innerHTML += currentTitle.charAt(this.letterIndex);
                    this.letterIndex++;
                } else {
                    titleText.innerHTML = currentTitle.substring(0, this.letterIndex);
                    this.letterIndex--;
                }
                
                setTimeout(this.typeWriter, interval);
            } else {
                // either reached the end of a forward type or finished deleting
                if (this.letterIndex < 0) {
                    // finished deleting
                    
                    // move to next title
                    if (this.titleIndex >= this.titles.length - 1) {
                        this.titleIndex = 0;
                    } else {
                        this.titleIndex++;
                    }

                    this.typeForward = true;
                    this.letterIndex += 1;
                    setTimeout(this.typeWriter, interval);
                } else {
                    // finishing typing forward
                    this.typeForward = false;

                    const holdInterval = 2500;

                    // hold it there for a moment
                    this.letterIndex -= 1;
                    setTimeout(this.typeWriter, holdInterval);
                }

                
            }
        },
    },
    mounted() {
        this.windowWidth = window.innerWidth;
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
        this.typeWriter();
    }
}
</script>

<style scoped>
    .home-header {
        background-image: url('../assets/background.png');
        background-repeat: repeat;
    }

    .title-container {
       min-width: 50%;
    }

    i {
        color: black;
    }

    i:hover {
        color: #175676;
    }

    .title-content {
        max-width: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: 768px) {
        .title-content {
            max-width: 100%;
        }
    }

    .profile-img-container {
        max-width: 50%;
    }

    .profile-img {
        max-width: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .btn-row {
        justify-content: space-around;
    }

    .portfolio-btn {
        border: 1px solid #175676;
        border-radius: 4px;
        font-size: 1.4em;
        min-width: 40%;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .portfolio-btn:hover {
        font-weight: bold;
    }

    .filled-btn {
        background-color: #175676;
        color: white;
    }

    .outlined-btn {
        background: transparent;
        color: black;
    }
</style>