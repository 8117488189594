<template>
  <div class="home" style="overflow:hidden;">
      <home-header/>
      <home-bio />
      <portfolio />
      <contact />
      <div class="my-5"></div>
  </div>
</template>

<script>
import Contact from '../components/Contact.vue'
import HomeBio from '../components/HomeBio.vue'
import HomeHeader from '../components/HomeHeader.vue'
import Portfolio from '../components/Portfolio.vue'

// const mockupAttribution = "https://previewed.app/";

export default {
  name: 'Home',
  components: {
    HomeHeader,
    HomeBio,
    Portfolio,
    Contact
  }
}
</script>
