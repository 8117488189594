<template>
    <div class="portfolio-container mt-2" id="portfolio">
        <h1>My Work</h1>
        <div v-if="projects==null">
            loading
        </div>
        <div v-else>
            <div v-for="(project, i) in projects" :key="project.id" class="container">
                <div class="row">
                    <div v-if="i%2==0 || isMobile" class="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center">
                        <img :src="project.images[0]" :alt="project.title" class="img-fluid display-img">
                    </div>
                    <div class="col-12 col-md-6 d-flex flex-column justify-content-center">
                        <h2><strong>{{project.title}}</strong></h2>
                        <h4 class="my-2">{{project.jobTitle}}
                            <span v-if="project.ongoing" class="ongoing-span"> | Ongoing</span>
                            <span v-else class="inactive-span"> | Inactive</span>
                        </h4>
                        <div class="my-2">
                            <p v-for="(paragraph, i) in project.paragraphs" :key="i" class="blurb-paragraph">
                                {{paragraph}}
                            </p>
                        </div>
                        <div v-if="project.appStore || project.playStore" class="d-flex flex-row justify-content-evenly align-items-center">
                            <a v-if="project.appStore" :href="project.appStore">
                                <img src="../assets/app-store-badge.svg" alt="" class="apple-badge">
                            </a>
                            <a v-if="project.playStore" :href="project.playStore">
                                <img src="../assets/google-play-badge.png" alt="" class="google-badge">
                            </a>
                        </div>
                    </div>
                    <div v-if="i%2!=0 && !isMobile" class="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center">
                        <img :src="project.images[0]" :alt="project.title" class="img-fluid display-img">
                    </div>
                </div>
                <hr class="w-75 mx-auto">
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    data() {
        return {
            windowWidth: null,
        }
    },
    computed: {
        ...mapState({
            projects: (state) => state.projects,
        }),
        isMobile() {
            return this.windowWidth < 786
        }
    },
    mounted(){
        this.windowWidth = window.innerWidth;
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    }
}
</script>

<style scoped>
    .blurb-paragraph {
        text-align: left;
        font-size: 1.2em;
        padding-left: 10px;
        padding-right: 10px;
    }

    .ongoing-span {
        color: #175676;
    }

    .inactive-span {
        color: lightslategrey;
    }

    .apple-badge {
        height: 50px;
    }

    .google-badge {
        height: 70px;
    }

    
</style>