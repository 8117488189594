<template>
    <div class="pt-3 d-flex flex-column align-items-start home-bio">
        <p v-for="(paragraph, i) in paragraphs" :key="i" class="bio-paragraph">
            {{paragraph}}
        </p>
        <p class="bio-paragraph">
            And I bet I’d love to work with <span class="you-text">you.</span>
        </p>
        <div class="divider"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            paragraphs: [
                "I am a freelance developer that obsesses over finding software solutions to real world problems. Whether it’s a business website, new app idea, or small personal project, I jump at the chance to choose the right stack and identify the best path forward.",
                "I like messing with new frameworks on lazy Sundays while I watch my roommates play Skyrim. I spend months coding half-baked ideas because I think it’s fun. I’m the guy who proposes making a web-app for a friend group drinking game to a chorus of groans in response.",
            ]
        }
    }
}
</script>

<style scoped>

    .home-bio {
        padding-left: 15px;
        padding-right: 15px;
    }

    .bio-paragraph {
        text-align: left;
        font-size: 1.2em;
    }

    @media (min-width: 768px) {
        .bio-paragraph {
            font-size: 1.4em;
        }

        .home-bio {
           max-width: 75%;
           margin-right: auto;
           margin-left: auto;
        }

        .you-text {
            font-size: 1.45;
        }
    }

    .you-text {
        color: #175676;
        font-weight: bold;
        font-size: 1.25em;
    }

    .divider {
        height: 1px;
        width: 100%;
        background-color: black;
    }
</style>